export function useSeoLangLink() {
  const {
    public: { HOST },
  } = useRuntimeConfig();
  const { locale, locales } = useI18n();
  const route = useRoute();
  const link = <any>locales.value.map((v: any) => {
    let href = "";
    if (locale.value === "en") {
      if (v.code === "en") {
        href = route.path;
      } else {
        href = "/" + v.code + route.path;
      }
      return {
        rel: "alternate",
        href: HOST + href,
        hreflang: v.iso,
      };
    } else {
      href = route.path.replace(
        "/" + locale.value,
        v.code == "en" ? "" : "/" + v.code
      );
      return {
        rel: "alternate",
        href: HOST + href,
        hreflang: v.iso,
      };
    }
  });
  let defaultHref = "";
  if (locale.value === "en") {
    defaultHref = HOST + route.path;
  } else {
    defaultHref = HOST + route.path.replace("/" + locale.value, "");
  }
  link.push({
    rel: "alternate",
    href: defaultHref,
    hreflang: "x-default",
  });
  link.push({
    rel: "canonical",
    href: defaultHref,
  });
  return link;
}
